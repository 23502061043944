import {
  SET_CONTEXT_MIN_DATE,
  SET_CONTEXT_MAX_DATE,
  SET_CONTEXT_DATE,
  SET_CONTEXT_TIME,
  SET_CONTEXT_TIMES,
  SET_BOOK_TOUR_STEP,
  SET_BOOK_TOUR,
  SET_GET_IN_TOUCH,
  SET_IMAGE_GALLERY_INDEX,
  SET_IMAGE_GALLERY_OPEN,
  SET_VALUE,
  RESET_STATE,
} from "./types"

const useActions = (prevState, dispatch) => {
  const actions = {
    setContextMinDate: date => {
      dispatch({ type: SET_CONTEXT_MIN_DATE, payload: date })
    },
    setContextMaxDate: date => {
      dispatch({ type: SET_CONTEXT_MAX_DATE, payload: date })
    },
    setContextDate: date => {
      dispatch({ type: SET_CONTEXT_DATE, payload: date })
    },
    setContextTime: time => {
      dispatch({ type: SET_CONTEXT_TIME, payload: time })
    },
    setContextTimes: times => {
      dispatch({ type: SET_CONTEXT_TIMES, payload: times })
    },
    setBookTourStep: step => {
      dispatch({ type: SET_BOOK_TOUR_STEP, payload: step })
    },
    setBookTour: bool => {
      dispatch({ type: SET_BOOK_TOUR, payload: bool })
    },
    setGetInTouch: bool => {
      dispatch({ type: SET_GET_IN_TOUCH, payload: bool })
    },
    setImageGalleryIndex: number => {
      dispatch({ type: SET_IMAGE_GALLERY_INDEX, payload: number })
    },
    setImageGalleryOpen: bool => {
      dispatch({ type: SET_IMAGE_GALLERY_OPEN, payload: bool })
    },
    resetState: () => {
      dispatch({ type: RESET_STATE })
    },
    setValue: value => {
      dispatch({ type: SET_VALUE, payload: value })
    },
  }
  return actions
}

export default useActions
