import {
  SET_CONTEXT_MIN_DATE,
  SET_CONTEXT_MAX_DATE,
  SET_CONTEXT_DATE,
  SET_CONTEXT_TIME,
  SET_CONTEXT_TIMES,
  SET_BOOK_TOUR_STEP,
  SET_BOOK_TOUR,
  SET_GET_IN_TOUCH,
  SET_IMAGE_GALLERY_INDEX,
  SET_IMAGE_GALLERY_OPEN,
  SET_VALUE,
  RESET_STATE,
} from "./types"
import qs from "query-string"

const isDev = value => (process.env.NODE_ENV === "development" ? value : "")

export const initialState = {
  contextMinDate: null,
  contextMaxDate: null,
  contextDate: null,
  contextEndDate: null,
  contextTime: null,
  startTime: "",
  endTime: "",
  profileFilterProgress: false,
  isDaily: true,
  availableContextTimes: [],
  filteredOptions: [],
  filteredOptionsByFloor: [],
  bookTourStep: 1,
  onDemandStep: 0,
  bookTour: false,
  bookTourComplete: false,
  getInTouch: false,
  lightBoxIndex: 0,
  openLightBox: false,
  showPerDayBooking: false,
  displayPrice: true,
  data: {},
  spaceSummary:
    typeof window !== "undefined" ? qs.parse(window.location.search) : {},
  optionId: "",
  optionValue: 0,
  hstPercentage: 0.13,
  optionHstValue: 0,
  optionTotal: 0,
  perDayResName: isDev("Mahesh"),
  perDayResEmail: isDev("mahesh@ensodigital.ca"),
  perDayResPhone: isDev("4039924525"),
  perDayResArrivalTime: isDev("9:00 am"),
  days: 1,
  optionPrice: 0,
  holidays: [],
  customHolidays: [],
  calendar: {},
  calendarLoaded: false,
  moveInDate: null,
  teamSize: 0,
  fetchedCommonData: false,
  openingTime: "9:00 AM",
  closingTime: "5:00 PM",
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTEXT_MIN_DATE:
      return { ...state, contextMinDate: action.payload }
    case SET_CONTEXT_MAX_DATE:
      return { ...state, contextMaxDate: action.payload }
    case SET_CONTEXT_DATE:
      return { ...state, contextDate: action.payload }
    case SET_CONTEXT_TIME:
      return { ...state, contextTime: action.payload }
    case SET_CONTEXT_TIMES:
      return { ...state, availableContextTimes: action.payload }
    case SET_BOOK_TOUR_STEP:
      return { ...state, bookTourStep: action.payload }
    case SET_BOOK_TOUR:
      return { ...state, bookTour: action.payload }
    case SET_GET_IN_TOUCH:
      return { ...state, getInTouch: action.payload }
    case SET_IMAGE_GALLERY_INDEX:
      return { ...state, lightBoxIndex: action.payload }
    case SET_IMAGE_GALLERY_OPEN:
      return { ...state, openLightBox: action.payload }
    case SET_VALUE:
      return { ...state, ...action.payload }
    case RESET_STATE:
      return {
        ...state,
        contextMinDate: null,
        contextMaxDate: null,
        contextDate: null,
        contextEndDate: null,
        contextTime: null,
        startTime: "",
        endTime: "",
        profileFilterProgress: false,
        isDaily: true,
        availableContextTimes: [],
        filteredOptions: [],
        filteredOptionsByFloor: [],
        bookTourStep: 1,
        onDemandStep: 0,
        bookTour: false,
        bookTourComplete: false,
        getInTouch: false,
        lightBoxIndex: 0,
        openLightBox: false,
        showPerDayBooking: false,
        displayPrice: true,
        data: {},
        spaceSummary:
          typeof window !== "undefined" ? qs.parse(window.location.search) : {},
        optionId: "",
        optionValue: 0,
        hstPercentage: 0.13,
        optionHstValue: 0,
        optionTotal: 0,
        perDayResName: isDev("Mahesh"),
        perDayResEmail: isDev("mahesh@ensodigital.ca"),
        perDayResPhone: isDev("4039924525"),
        perDayResArrivalTime: isDev("9:00 am"),
        days: 1,
        optionPrice: 0,
        holidays: [],
        calendar: {},
        calendarLoaded: false,
        moveInDate: null,
        teamSize: 0,
      }
    default:
      return { ...state }
  }
}

export default reducer
